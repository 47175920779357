import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import HTMLtoDOCX from 'html-to-docx';
import juice from 'juice';
import * as XLSX from 'xlsx';
import slug from 'slug';
import _forEach from 'lodash/forEach';
import store from 'store';

import {
  fetchSubtitles as fetchSubtitlesAction,
  generateSelectorForWordObjectsBySectionId,
  selectCollectionLanguage,
  selectCollectionName,
  selectCollectionText,
  selectCollectionPlainText,
  selectGenerateAnnotations,
  selectCollectionDescription
} from 'features/collections';
import { fetchHighlights } from 'features/highlights';
import {
  selectSpeakerIds,
  selectCollectionSpeakers,
  fetchSpeakers
} from 'features/speakers';

import { fetchTranscript } from 'features/transcripts';
import useProfile from 'hooks/useProfile';
import { getTimeStamp } from 'components/common/Timer/convert-time';

const getShortCID = (cid) => cid.split('-').slice(0, 1).join('');

const useExporter = ({ collectionId, canonicalRef }) => {
  const dispatch = useDispatch();
  const {
    settings: { footerText, headerText, filenameAsTitle }
  } = useProfile();
  const name = useSelector((state) =>
    selectCollectionName(state, collectionId)
  );
  const language = useSelector((state) =>
    selectCollectionLanguage(state, collectionId)
  );
  const description = useSelector((state) =>
    selectCollectionDescription(state, collectionId)
  );

  const [loading, setLoading] = useState(false);
  const [downloadingSRT, setDownloadingSRT] = useState(false);
  const [downloadingVTT, setDownloadingVTT] = useState(false);

  const fetchDetails = async () => {
    const { transcripts, speakers, highlights } = store.getState();
    if (
      transcripts.entities[collectionId] &&
      speakers.entities[collectionId] &&
      highlights.entities[collectionId]
    )
      return;
    setLoading(true);
    try {
      await Promise.all([
        dispatch(fetchHighlights({ collectionId })),
        dispatch(fetchSpeakers({ collectionId })),
        dispatch(fetchTranscript({ collectionId }))
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubtitles = async (format = 'srt', size = 35, breakAllSpeakers) =>
    dispatch(
      fetchSubtitlesAction({
        id: collectionId,
        format,
        size,
        breakAllSpeakers,
        from: 'export'
      })
    );

  const getDownloadName = (ext = 'txt', cid = '') => {
    const fileName = slug((name || '').replace('...', '')) || getShortCID(cid);
    return `${fileName}_${language}.${ext}`;
  };

  const getText = async (speakers, filter) => {
    await fetchDetails();
    const state = store.getState();

    const collectionText = speakers
      ? selectCollectionText(state, collectionId, filter)
      : selectCollectionPlainText(state, collectionId, filter);
    return collectionText;
  };

  const generateText = async (speakers = true, filter) => {
    console.log({ speakers, filter });
    const collectionText = await getText(speakers, filter);
    const b = new Blob([collectionText], { type: 'text/plain;charset=utf-8' });
    saveAs(b, getDownloadName('txt', speakers ? 'speakers' : 'plain'));
    return Promise.resolve();
  };

  const generateSubtitles = async (
    format = 'srt',
    size = 35,
    breakAllSpeakers
  ) => {
    await fetchDetails();
    const response =
      (await fetchSubtitles(format, size, breakAllSpeakers)) || {};
    console.log('subs', { response });
    const { payload: subtitles } = response;
    const b = new Blob([subtitles], { type: `text/${format};charset=utf-8` });
    saveAs(b, getDownloadName(format));
  };

  const generateSrtSubtitles = async (size) => {
    setDownloadingSRT(true);
    await fetchDetails();
    await generateSubtitles('srt', size);
    setDownloadingSRT(false);
  };

  const generateVttSubtitles = async (size) => {
    setDownloadingVTT(true);
    await fetchDetails();
    await generateSubtitles('vtt', size);
    setDownloadingVTT(false);
  };

  // TODO: same prepare as XLS but use XLSX.utils.sheet_to_csv(worksheet)
  const downloadCsvAnnotations = async (fileName, payload) => {
    await fetchDetails();
    let text = 'Start,End,Speaker,Annotation,Highlighted,Tags \n';
    for (let highlight of payload) {
      const {
        highlighted,
        content,
        startTime,
        endTime,
        speaker,
        tags = {}
      } = highlight;
      if (highlighted) {
        const tagsArr = Object.keys(tags);
        const h = highlighted
          .replace(/[ \n\t]+(!|\?|,|\.)[ \n\t]+/g, '$1 ')
          .replace(/ (\.|,|\?|!)$/, '$1')
          .trim(' ');
        text += `"${getTimeStamp(startTime)}","${getTimeStamp(endTime)}","${
          speaker || ''
        }","${content}","${h}","${(tagsArr || []).join(', ')}" \n`;
      }
    }
    if (payload.length === 0)
      text += 'Please highlight content to populate this report';
    const b = new Blob([text], { type: 'text/csv' });
    saveAs(b, fileName, { autoBom: true });
    return Promise.resolve();
  };

  const downloadXlsAnnotations = async (fileName, payload) => {
    await fetchDetails();

    const worksheet = XLSX.utils.book_new();
    const rows = [];
    rows.push([]);
    rows.push(['Start', 'End', 'Speaker', 'Annotation', 'Highlighted', 'Tags']);

    for (let highlight of payload) {
      const {
        highlighted,
        content,
        startTime,
        endTime,
        speaker,
        tags = {}
      } = highlight;
      const tagsArr = Object.keys(tags);
      if (highlighted) {
        const h = highlighted
          .replace(/[ \n\t]+(!|\?|,|\.)[ \n\t]+/g, '$1 ')
          .replace(/ (\.|,|\?|!)$/, '$1')
          .trim(' ');
        rows.push([
          getTimeStamp(startTime),
          getTimeStamp(endTime),
          speaker || '',
          content,
          h,
          (tagsArr || []).join(', ')
        ]);
      }
    }

    if (payload.length === 0)
      rows.push(['Please highlight content to populate this report']);

    console.log({ rows });
    XLSX.utils.sheet_add_aoa(worksheet, rows);
    const wb = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(wb, fileName);

    return Promise.resolve();
  };

  const generateAnnotations = async (isCsv) => {
    await fetchDetails();
    const state = store.getState();

    const annotations = selectGenerateAnnotations(state, collectionId);

    return isCsv
      ? downloadCsvAnnotations(getDownloadName('csv'), annotations)
      : downloadXlsAnnotations(getDownloadName('xls'), annotations);
  };

  const downloadCsv = async () => {
    await fetchDetails();
    const state = store.getState();
    const speakerIds = selectSpeakerIds(state, collectionId);
    const speakers = selectCollectionSpeakers(state, collectionId);
    let text = '"Start","End","Speaker","Text"\n';
    _forEach(speakerIds, (id, i) => {
      const getWords = generateSelectorForWordObjectsBySectionId();
      const words = getWords(state, collectionId, id);
      const speaker = speakers[id]?.name || '';
      const safeWords = words.filter((word) => word.end_time);
      const endOfSectionTime =
        safeWords.slice(-1)[0]?.end_time ||
        safeWords.slice(-2)[0]?.end_time ||
        safeWords.slice(-3)[0]?.end_time;
      console.log(endOfSectionTime, safeWords);
      const content = words
        .map((word) => word?.alternatives[0]?.content)
        .join(' ')
        .replace(/ (\p{P})/gmu, '$1');
      if (content)
        text += `"${getTimeStamp(id)}","${
          endOfSectionTime ? getTimeStamp(endOfSectionTime) : ''
        }","${speaker || ''}","${content}"\n`;
    });

    const b = new Blob(['\ufeff' + text], { type: 'text/csv;charset=utf-8' });
    const fileName = getDownloadName('csv');
    saveAs(b, fileName, { autoBom: true });
    return Promise.resolve();
  };

  const generateDoc = async (formatted, speakerHeader) => {
    await fetchDetails();
    const state = store.getState();
    const speakerIds = selectSpeakerIds(state, collectionId);
    const speakers = selectCollectionSpeakers(state, collectionId);

    const style = `
      <style>
        * {
          font-family: Arial !important;
          margin: 0;
          padding: 0;
        }
        body {
          font-size: 11pt;
          line-height: 1.5;
        }
        .title {
          font-size: 14pt;
          font-weight: bold;
          margin-bottom: 12pt;
        }
        .speaker-header {
          font-family: Arial !important;
          font-size: 13.5pt;
          font-weight: bold;
          margin-bottom: 6pt;
          margin-top: 12pt;
        }
        .transcript-text {
          font-family: Arial !important;
          font-size: 11pt;
          font-weight: normal;
          margin-bottom: 12pt;
          line-height: 1.5;
        }
        .description {
          margin-bottom: 12pt;
        }
        table {
          width: 100%;
          border-collapse: collapse;
        }
        td {
          font-family: Arial !important;
          font-size: 11pt;
          vertical-align: top;
          padding: 8px;
        }
        td.left {
          width: 150px;
        }
        span[data-bookmark] {
          background-color: yellow;
        }
      </style>
    `;

    const bodyHtml = speakerIds
      .map((id) => {
        const getWords = generateSelectorForWordObjectsBySectionId();
        const words = getWords(state, collectionId, id);
        const wordsText = words
          .map((wordObject) => wordObject.alternatives[0].content)
          .join(' ')
          .replace(/ +/g, ' ')
          .replace(/ (\p{P})/gmu, '$1');

        if (formatted) {
          return `<tr>
            <td class="left">${getTimeStamp(id)}${
              speakers[id]?.name ? ' ' + speakers[id].name : ''
            }</td>
            <td>${wordsText}</td>
          </tr>`;
        } else {
          const endTime = words.slice(-1)[0]?.start_time || id;
          const speakerName = speakers[id]?.name;

          if (speakerHeader) {
            const headerText = speakerName
              ? `${speakerName} [${getTimeStamp(id)}]`
              : `[${getTimeStamp(id)}]`;

            return `<div class="speaker-header">${headerText}</div>
  <div class="transcript-text">${wordsText}</div>`;
          } else {
            const timeRange = `[${getTimeStamp(
              id,
              false,
              false,
              true
            )} - ${getTimeStamp(endTime, false, false, true)}]`;
            const speakerPrefix = speakerName ? ` ${speakerName}: ` : ' ';

            return `<div class="transcript-text">${timeRange}${speakerPrefix}${wordsText}</div>`;
          }
        }
      })
      .join('');

    const formattedDescription = (description || '').replaceAll('\n', '<br />');
    const formattedHeaderText = (headerText || '').replaceAll('\\n', '<br />');
    const title = filenameAsTitle && name ? name : '';

    const html = `<!DOCTYPE html>
  <html>
  <head>
  <meta charset="UTF-8">
  ${style}
  </head>
  <body>${title ? `<div class="title">${title}</div>` : ''}${
    formattedDescription
      ? `<div class="description">${formattedDescription}</div><br />`
      : ''
  }${
    formattedHeaderText
      ? `<div class="header-text">${formattedHeaderText}</div>`
      : ''
  }${formatted ? `<table>${bodyHtml}</table>` : bodyHtml}${
    footerText ? `<div class="footer">${footerText}</div>` : ''
  }</body>
  </html>`;

    const juiced = juice(html, { inlinePseudoElements: true });

    const docx = await HTMLtoDOCX(juiced, {
      margins: { top: 1440, right: 1440, bottom: 1440, left: 1440 }, // 1 inch margins
      font: 'Arial',
      fontSize: 11,
      styles: {
        paragraphSpacing: {
          before: 0,
          after: 0
        }
      }
    });

    const blob = new Blob([docx], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    });
    const fileName = getDownloadName('docx');
    saveAs(blob, fileName);
  };

  return {
    loading,
    downloadingSRT,
    downloadingVTT,
    generateAnnotations,
    downloadCsv,
    getText,
    generateDoc,
    generateSubtitles,
    generateSrtSubtitles,
    generateVttSubtitles,
    generateText
  };
};

export default useExporter;
