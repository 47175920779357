import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const AR = 'ar'; // ARABIC"
const BA = 'ba'; // BASHKIR"
const EU = 'eu'; // BASQUE"
const BE = 'be'; // BELARUSIAN"
const BG = 'bg'; // BULGARIAN"
const YUE = 'yue'; // CANTONESE"
const CA = 'ca'; // CATALAN"
const HR = 'hr'; // CROATIAN"
const CS = 'cs'; // CZECH"
const DA = 'da'; // DANISH"
const NL = 'nl'; // DUTCH"
const EN = 'en'; // ENGLISH"
const EO = 'eo'; // ESPERANTO"
const ET = 'et'; // ESTONIAN"
const FI = 'fi'; // FINNISH"
const FR = 'fr'; // FRENCH"
const GL = 'gl'; // GALICIAN"
const DE = 'de'; // GERMAN"
const EL = 'el'; // GREEK"
const HI = 'hi'; // HINDI"
const HU = 'hu'; // HUNGARIAN"
const IA = 'ia'; // INTERLINGUA"
const IT = 'it'; // ITALIAN"
const ID = 'id'; // INDONESIAN"
const JA = 'ja'; // JAPANESE"
const KO = 'ko'; // KOREAN"
const LV = 'lv'; // LATVIAN"
const LT = 'lt'; // LITHUANIAN"
const MS = 'ms'; // MALAY"
const CMN = 'cmn'; // MANDARIN"
const MR = 'mr'; // MARATHI"
const MN = 'mn'; // MONGOLIAN"
const NO = 'no'; // NORWEGIAN"
const PL = 'pl'; // POLISH"
const PT = 'pt'; // PORTUGUESE"
const RO = 'ro'; // ROMANIAN"
const RU = 'ru'; // RUSSIAN"
const SK = 'sk'; // SLOVAKIAN"
const SL = 'sl'; // SLOVENIAN"
const ES = 'es'; // SPANISH"
const SV = 'sv'; // SWEDISH"
const TA = 'ta'; // TAMIL"
const TH = 'th'; // THAI"
const TR = 'tr'; // TURKISH"
const UG = 'ug'; // UYGHUR"
const UK = 'uk'; // UKRAINIAN"
const VI = 'vi'; // VIETNAMESE"
const CY = 'cy'; // WELSH"
const FA = 'fa'; // PERSIAN"
const HE = 'he'; // HEBREW"
const ESEN = 'esen'; // ENGLISH & SPANISH"
const BN = 'bn'; // BENGALI
const GA = 'ga'; // IRISH
const MT = 'mt'; // Maltese
const SW = 'sw'; // SWAHILI
const UR = 'ur'; // URDU

export const noSpaceLanguages = { ja: true, yue: true, cmn: true, th: true };

export const LanguageLookup = [
  { label: 'Auto Detect', value: 'auto' },
  { label: 'Arabic', value: AR },
  { label: 'Bashkir', value: BA },
  { label: 'Basque', value: EU },
  { label: 'Belarusian', value: BE },
  { label: 'Bulgarian', value: BG },
  { label: 'Cantonese', value: YUE },
  { label: 'Catalan', value: CA },
  { label: 'Croatian', value: HR },
  { label: 'Czech', value: CS },
  { label: 'Danish', value: DA },
  { label: 'Dutch', value: NL },
  { label: 'English', value: EN },
  { label: 'Esperanto', value: EO },
  { label: 'Estonian', value: ET },
  { label: 'Finnish', value: FI },
  { label: 'French', value: FR },
  { label: 'Galician', value: GL },
  { label: 'German', value: DE },
  { label: 'Greek', value: EL },
  { label: 'Hindi', value: HI },
  { label: 'Hungarian', value: HU },
  { label: 'Interlingua', value: IA },
  { label: 'Italian', value: IT },
  { label: 'Indonesian', value: ID },
  { label: 'Japanese', value: JA },
  { label: 'Korean', value: KO },
  { label: 'Latvian', value: LV },
  { label: 'Lithuanian', value: LT },
  { label: 'Malay', value: MS },
  { label: 'Mandarin', value: CMN },
  { label: 'Marathi', value: MR },
  { label: 'Mongolian', value: MN },
  { label: 'Norwegian', value: NO },
  { label: 'Polish', value: PL },
  { label: 'Portuguese', value: PT },
  { label: 'Persian', value: FA },
  { label: 'Romanian', value: RO },
  { label: 'Russian', value: RU },
  { label: 'Slovak', value: SK },
  { label: 'Slovenian', value: SL },
  { label: 'Spanish', value: ES },
  { label: 'Swedish', value: SV },
  { label: 'Tamil', value: TA },
  { label: 'Thai', value: TH },
  { label: 'Turkish', value: TR },
  { label: 'Uyghur', value: UG },
  { label: 'Ukrainian', value: UK },
  { label: 'Vietnamese', value: VI },
  { label: 'Welsh', value: CY },
  { label: 'Hebrew', value: HE },
  { label: 'English & Spanish', value: ESEN },
  { label: 'Bengali', value: BN },
  { label: 'Irish', value: GA },
  { label: 'Maltese', value: MT },
  { label: 'Swahili', value: SW },
  { label: 'Urdu', value: UR }
];

export const TranslateLookup = [
  { value: 'af', label: 'Afrikaans' },
  { value: 'sq', label: 'Albanian' },
  { value: 'am', label: 'Amharic' },
  { value: 'ar', label: 'Arabic' },
  { value: 'hy', label: 'Armenian' },
  { value: 'az', label: 'Azerbaijani' },
  { value: 'bn', label: 'Bengali' },
  { value: 'bs', label: 'Bosnian' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'ca', label: 'Catalan' },
  { value: 'zh', label: 'Chinese (Simplified)' },
  { value: 'zh-TW', label: 'Chinese (Traditional)' },
  { value: 'hr', label: 'Croatian' },
  { value: 'cs', label: 'Czech' },
  { value: 'da', label: 'Danish' },
  { value: 'fa-AF', label: 'Dari' },
  { value: 'nl', label: 'Dutch' },
  { value: 'en', label: 'English' },
  { value: 'et', label: 'Estonian' },
  { value: 'fa', label: 'Farsi (Persian)' },
  { value: 'tl', label: 'Filipino, Tagalog' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fr', label: 'French' },
  { value: 'fr-CA', label: 'French (Canada)' },
  { value: 'ka', label: 'Georgian' },
  { value: 'de', label: 'German' },
  { value: 'el', label: 'Greek' },
  { value: 'gu', label: 'Gujarati' },
  { value: 'ht', label: 'Haitian Creole' },
  { value: 'ha', label: 'Hausa' },
  { value: 'he', label: 'Hebrew' },
  { value: 'hi', label: 'Hindi' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'is', label: 'Icelandic' },
  { value: 'id', label: 'Indonesian' },
  { value: 'ga', label: 'Irish' },
  { value: 'it', label: 'Italian' },
  { value: 'ja', label: 'Japanese' },
  { value: 'kn', label: 'Kannada' },
  { value: 'kk', label: 'Kazakh' },
  { value: 'ko', label: 'Korean' },
  { value: 'lv', label: 'Latvian' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'ms', label: 'Malay' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'mt', label: 'Maltese' },
  { value: 'mr', label: 'Marathi' },
  { value: 'mn', label: 'Mongolian' },
  { value: 'no', label: 'Norwegian' },
  { value: 'ps', label: 'Pashto' },
  { value: 'pl', label: 'Polish' },
  { value: 'pt', label: 'Portuguese (Brazil)' },
  { value: 'pt-PT', label: 'Portuguese (Portugal)' },
  { value: 'pa', label: 'Punjabi' },
  { value: 'ro', label: 'Romanian' },
  { value: 'ru', label: 'Russian' },
  { value: 'sr', label: 'Serbian' },
  { value: 'si', label: 'Sinhala' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'so', label: 'Somali' },
  { value: 'es', label: 'Spanish' },
  { value: 'es-MX', label: 'Spanish (Mexico)' },
  { value: 'sw', label: 'Swahili' },
  { value: 'sv', label: 'Swedish' },
  { value: 'ta', label: 'Tamil' },
  { value: 'te', label: 'Telugu' },
  { value: 'th', label: 'Thai' },
  { value: 'tr', label: 'Turkish' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'ur', label: 'Urdu' },
  { value: 'uz', label: 'Uzbek' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'cy', label: 'Welsh' }
];

export const LanguageIndexByCode = LanguageLookup.reduce((acc, lang) => {
  acc[lang.value.split('-')[0]] = { ...lang };
  return acc;
}, {});

export const TranslateIndexByCode = TranslateLookup.reduce((acc, lang) => {
  acc[lang.value.split('-')[0]] = { ...lang };
  return acc;
}, {});

export const LanguageIndexByLabel = LanguageLookup.reduce((acc, lang) => {
  acc[lang.label] = lang.value;
  return acc;
}, {});

export const isPrecisionEnabled = ({ languageCode }) =>
  (LanguageIndexByCode[(languageCode || '').split('-')[0]] || {}).precision;

const SelectLanguageCont = styled.div`
  color: rgba(250, 250, 250, 0.4);
  margin-bottom: 10px;
  & .select__menu {
    color: #333;
  }
`;

const LanguageDefault = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 4px 0;
  padding: 4px 0;
  opacity: 0.2;
  &:hover {
    opacity: 1;
    transition: opacity 0.7s;
    transition-delay: 0.3s;
  }
  transition: opacity 1s;
  transition-delay: 2s;
  ${(props) =>
    props.hide &&
    'visibility: hidden; opacity: 0; height: 0; width: 0; margin: 0; padding: 0;'}

  .MuiSwitch-root {
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: lightgreen;
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-thumb {
      background-color: #eee;
    }
    .MuiSwitch-thumb {
      background-color: #ccc;
    }
    .MuiSwitch-track {
      background-color: #333;
    }
  }
`;

const SelectFormControl = styled(FormControl)`
  width: 100%;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  .MuiSelect-root {
    padding: 9px;
  }
  ${({ value }) =>
    value === 'none' &&
    `
      .MuiSelect-select {
        color: #777;
      }
    `}

  svg {
    margin-right: 8px;
    padding-left: 4px;
    border-left: 1px solid #ccc;
  }
  &:before,
  &:after {
    content: none !important;
  }
`;

const MUIAutoComplete = styled(Autocomplete)`
  button {
    display: none;
  }
`;

export const SimpleSelect = (props) => {
  const {
    variant,
    defaultLanguage: initDefaultLanguage,
    onChange,
    excludeLanguages,
    languageLookup = LanguageLookup,
    filter = 'value',
    size = 'small',
    required = false,
    removeAutoDetect = false
  } = props;

  let availableLanguages = languageLookup.filter((item) => !!item[filter]);

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [languages, setLanguages] = useState(availableLanguages);
  const [language, setLanguage] = useState('');

  const [languageInputVal, setLanguageInputVal] = useState('');
  const [hasDefaultLangOption, setHasDefaultLangOption] = useState(false);

  if (removeAutoDetect) {
    availableLanguages = availableLanguages.filter(
      (item) => item.value !== 'auto'
    );
  }

  availableLanguages = availableLanguages.filter(({ value }) =>
    (excludeLanguages || []).indexOf((value || '').split('-')[0])
  );
  availableLanguages = availableLanguages.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  // console.log({excludeLanguages, initDefaultLanguage})
  let defaultLanguage =
    !excludeLanguages ||
    (excludeLanguages || []).indexOf(
      (initDefaultLanguage || '').split('-')[0]
    ) >= 0
      ? initDefaultLanguage
      : undefined;
  defaultLanguage = defaultLanguage
    ? defaultLanguage.split('-')[0]
    : defaultLanguage;

  const value = language || defaultLanguage || '';
  // const getLangLabel = (langValue) => languages.find(lang => lang.value === langValue)?.label || ''
  const shortLang = (value || '').split('-')[0];

  const [autCompleteVal, setAutCompleteVal] = useState(
    value
      ? {
          value,
          label: `${LanguageIndexByCode[shortLang]?.label}${
            defaultLanguage ? ' (Default)' : ''
          }`
        }
      : null
  );

  const handleChange = (selectedValue) => {
    const language = selectedValue?.value;
    setLanguage(language);
    setAutCompleteVal(selectedValue);
    setIsDropdownOpened(false);
    onChange && onChange(language);
  };

  useEffect(() => {
    setLanguage(shortLang);
  }, []);

  useEffect(() => {
    if (defaultLanguage) {
      if (hasDefaultLangOption) {
        languages[0] = {
          label: `${LanguageIndexByCode[defaultLanguage]?.label} (Default)`,
          value: defaultLanguage
        };
      } else {
        languages.unshift({
          label: `${LanguageIndexByCode[defaultLanguage]?.label} (Default)`,
          value: defaultLanguage
        });
      }
      setHasDefaultLangOption(true);
      setLanguages(languages);
      setAutCompleteVal(languages[0]);
      return;
    }

    if (hasDefaultLangOption) {
      languages.shift();
      setLanguages(languages);
      setLanguage(language);
      setAutCompleteVal({
        label: LanguageIndexByCode[language]?.label,
        value: language
      });
      setHasDefaultLangOption(false);
    }
  }, [defaultLanguage]);

  return (
    <SelectFormControl variant={variant} value={value}>
      <MUIAutoComplete
        debug={true}
        options={languages}
        open={isDropdownOpened}
        value={autCompleteVal}
        onChange={(_, selectedLanguage) => handleChange(selectedLanguage)}
        inputValue={languageInputVal}
        onInputChange={(_, newInputValue) => setLanguageInputVal(newInputValue)}
        onOpen={() => setIsDropdownOpened(true)}
        onBlur={() => setIsDropdownOpened(false)}
        onClose={(e) => {
          if (e.key === 'Escape') {
            e.target.blur();
            handleChange(languages.find((l) => l.value === value));
          }
        }}
        getOptionLabel={(val) => val.label}
        getOptionSelected={(option, value) => option?.value === value?.value}
        classes={{
          listbox: 'language-select-listBox',
          popper: 'force-top-popper'
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size={size}
            label="Select a Language"
            required={required}
          />
        )}
      />
    </SelectFormControl>
  );
};

export default ({
  onSelectLanguage,
  onSetLanguageDefault,
  defaultLanguage,
  languageCode,
  required,
  removeAutoDetect
}) => {
  return (
    <>
      <SelectLanguageCont>
        <SimpleSelect
          displayEmpty
          defaultLanguage={defaultLanguage}
          onChange={(selectedLanguage) => {
            onSelectLanguage && onSelectLanguage(selectedLanguage);
          }}
          required={required}
          removeAutoDetect={removeAutoDetect}
        />
        <LanguageDefault hide={!languageCode}>
          <span>Set language as default</span>
          <Switch
            value={defaultLanguage || ''}
            checked={!!defaultLanguage && defaultLanguage === languageCode}
            onChange={(evt, on) =>
              onSetLanguageDefault(on ? languageCode : null)
            }
          />
        </LanguageDefault>
      </SelectLanguageCont>
    </>
  );
};
