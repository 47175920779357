/* eslint-disable indent */
import {
  fetchSubtitles as fetchSubtitlesAction,
  generateSelectorForWordObjectsBySectionId,
  selectCollectionLanguage,
  selectCollectionName,
  selectCollectionPlainText,
  selectCollectionText,
  selectGenerateAnnotations,
  selectCollectionDescription
} from 'features/collections';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import store from 'store';
import { fetchHighlights } from 'features/highlights';
import {
  fetchSpeakers,
  selectCollectionSpeakers,
  selectSpeakerIds
} from 'features/speakers';

import useProfile from 'hooks/useProfile';
import { fetchTranscript } from 'features/transcripts';
import juice from 'juice';
import slug from 'slug';
import * as XLSX from 'xlsx';
import { getTimeStamp } from 'components/common/Timer/convert-time';
import { saveAs } from 'file-saver';
import HTMLtoDOCX from 'html-to-docx';
import _forEach from 'lodash/forEach';

const BATCH_SIZE = 5;
const BATCH_DELAY = 1000;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const useMultiExporter = ({ collectionIds }) => {
  const dispatch = useDispatch();
  const {
    settings: { footerText, headerText, filenameAsTitle }
  } = useProfile();
  const [completedCollections, setCompletedCollections] = useState(new Map());
  const [isGeneratingDocs, setIsGeneratingDocs] = useState(false);

  const getShortCID = (cid) => cid.split('-').slice(0, 1).join('');

  const getName = (collectionId) => {
    const state = store.getState();
    return selectCollectionName(state, collectionId);
  };

  const getLanguage = (collectionId) => {
    const state = store.getState();
    return selectCollectionLanguage(state, collectionId);
  };

  const fetchSubtitles = async (
    format = 'srt',
    size = 35,
    breakAllSpeakers,
    collectionId
  ) =>
    dispatch(
      fetchSubtitlesAction({
        id: collectionId,
        format,
        size,
        breakAllSpeakers,
        from: 'export'
      })
    );

  const getDownloadName = (ext = 'txt', cid = '') => {
    const name = getName(cid);
    const language = getLanguage(cid);

    const fileName = slug((name || '').replace('...', '')) || getShortCID(cid);
    return `${fileName}_${language}.${ext}`;
  };

  const fetchDetails = async (collectionId) => {
    const { transcripts, speakers, highlights } = store.getState();

    if (
      transcripts.entities[collectionId] &&
      speakers.entities[collectionId] &&
      highlights.entities[collectionId]
    ) {
      // Since we are not fetching details again the promise resolves very quickly
      // Which can cause browser overload thus we are adding a delay here.
      await delay(1000);
      return;
    }

    try {
      await Promise.all([
        dispatch(fetchHighlights({ collectionId })),
        dispatch(fetchSpeakers({ collectionId })),
        dispatch(fetchTranscript({ collectionId }))
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  // Internal Export Functions

  const generateDoc = async (formatted, speakerHeader, collectionId) => {
    await fetchDetails(collectionId);
    const state = store.getState();
    const speakerIds = selectSpeakerIds(state, collectionId);
    const speakers = selectCollectionSpeakers(state, collectionId);
    const description = selectCollectionDescription(state, collectionId);
    const name = getName(collectionId);

    const style = `
      <style>
        * {
          font-family: Arial !important;
          margin: 0;
          padding: 0;
        }
        body {
          font-size: 11pt;
          line-height: 1.5;
        }
        .title {
          font-size: 14pt;
          font-weight: bold;
          margin-bottom: 12pt;
        }
        .speaker-header {
          font-family: Arial !important;
          font-size: 13.5pt;
          font-weight: bold;
          margin-bottom: 6pt;
          margin-top: 12pt;
        }
        .transcript-text {
          font-family: Arial !important;
          font-size: 11pt;
          font-weight: normal;
          margin-bottom: 12pt;
          line-height: 1.5;
        }
        .description {
          margin-bottom: 12pt;
        }
        table {
          width: 100%;
          border-collapse: collapse;
        }
        td {
          font-family: Arial !important;
          font-size: 11pt;
          vertical-align: top;
          padding: 8px;
        }
        td.left {
          width: 150px;
        }
        span[data-bookmark] {
          background-color: yellow;
        }
      </style>
    `;

    const bodyHtml = speakerIds
      .map((id) => {
        const getWords = generateSelectorForWordObjectsBySectionId();
        const words = getWords(state, collectionId, id);
        const wordsText = words
          .map((wordObject) => wordObject.alternatives[0].content)
          .join(' ')
          .replace(/ +/g, ' ')
          .replace(/ (\p{P})/gmu, '$1');

        if (formatted) {
          return `<tr>
            <td class="left">${getTimeStamp(id)}${
              speakers[id]?.name ? ' ' + speakers[id].name : ''
            }</td>
            <td>${wordsText}</td>
          </tr>`;
        } else {
          const endTime = words.slice(-1)[0]?.start_time || id;
          const speakerName = speakers[id]?.name;

          if (speakerHeader) {
            const headerText = speakerName
              ? `${speakerName} [${getTimeStamp(id)}]`
              : `[${getTimeStamp(id)}]`;

            return `<div class="speaker-header">${headerText}</div>
  <div class="transcript-text">${wordsText}</div>`;
          } else {
            const timeRange = `[${getTimeStamp(
              id,
              false,
              false,
              true
            )} - ${getTimeStamp(endTime, false, false, true)}]`;
            const speakerPrefix = speakerName ? ` ${speakerName}: ` : ' ';

            return `<div class="transcript-text">${timeRange}${speakerPrefix}${wordsText}</div>`;
          }
        }
      })
      .join('');

    const formattedDescription = (description || '').replaceAll('\n', '<br />');
    const formattedHeaderText = (headerText || '').replaceAll('\\n', '<br />');
    const title = filenameAsTitle && name ? name : '';

    const html = `<!DOCTYPE html>
  <html>
  <head>
  <meta charset="UTF-8">
  ${style}
  </head>
  <body>${title ? `<div class="title">${title}</div>` : ''}${
    formattedDescription
      ? `<div class="description">${formattedDescription}</div><br />`
      : ''
  }${
    formattedHeaderText
      ? `<div class="header-text">${formattedHeaderText}</div>`
      : ''
  }${formatted ? `<table>${bodyHtml}</table>` : bodyHtml}${
    footerText ? `<div class="footer">${footerText}</div>` : ''
  }</body>
  </html>`;

    const juiced = juice(html, { inlinePseudoElements: true });

    const docx = await HTMLtoDOCX(juiced, {
      margins: { top: 1440, right: 1440, bottom: 1440, left: 1440 }, // 1 inch margins
      font: 'Arial',
      fontSize: 11,
      styles: {
        paragraphSpacing: {
          before: 0,
          after: 0
        }
      }
    });

    const blob = new Blob([docx], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    });
    const fileName = getDownloadName('docx', collectionId);
    saveAs(blob, fileName);
  };

  const downloadCsv = async (fileName, collectionId) => {
    await fetchDetails(collectionId);
    const state = store.getState();
    const speakerIds = selectSpeakerIds(state, collectionId);
    const speakers = selectCollectionSpeakers(state, collectionId);
    let text = '"Start","End","Speaker","Text"\n';
    _forEach(speakerIds, (id, i) => {
      const getWords = generateSelectorForWordObjectsBySectionId();
      const words = getWords(state, collectionId, id);
      const speaker = speakers[id]?.name || '';
      const safeWords = words.filter((word) => word.end_time);
      const endOfSectionTime =
        safeWords.slice(-1)[0]?.end_time ||
        safeWords.slice(-2)[0]?.end_time ||
        safeWords.slice(-3)[0]?.end_time;
      console.log(endOfSectionTime, safeWords);
      const content = words
        .map((word) => word?.alternatives[0]?.content)
        .join(' ')
        .replace(/ (\p{P})/gmu, '$1');
      if (content)
        text += `"${getTimeStamp(id)}","${
          endOfSectionTime ? getTimeStamp(endOfSectionTime) : ''
        }","${speaker || ''}","${content}"\n`;
    });

    const b = new Blob([text], { type: 'text/csv' });
    saveAs(b, fileName, { autoBom: true });
    return Promise.resolve();
  };

  const getText = async (speakers, filter, collectionId) => {
    await fetchDetails(collectionId);
    const state = store.getState();

    const collectionText = speakers
      ? selectCollectionText(state, collectionId, filter)
      : selectCollectionPlainText(state, collectionId, filter);

    return collectionText;
  };

  const generateSubtitles = async (
    format = 'srt',
    size = 35,
    collectionId,
    breakAllSpeakers
  ) => {
    await fetchDetails(collectionId);

    const response =
      (await fetchSubtitles(format, size, breakAllSpeakers, collectionId)) ||
      {};

    console.log('subs', { response });

    const { payload: subtitles } = response;

    const b = new Blob([subtitles], { type: `text/${format};charset=utf-8` });
    saveAs(b, getDownloadName(format, collectionId));
  };

  // TODO: same prepare as XLS but use XLSX.utils.sheet_to_csv(worksheet)
  const downloadCsvAnnotations = async (fileName, payload, collectionId) => {
    await fetchDetails(collectionId);
    let text = 'Start,End,Speaker,Annotation,Highlighted,Tags \n';
    for (let highlight of payload) {
      const {
        highlighted,
        content,
        startTime,
        endTime,
        speaker,
        tags = {}
      } = highlight;
      if (highlighted) {
        const tagsArr = Object.keys(tags);
        const h = highlighted
          .replace(/[ \n\t]+(!|\?|,|\.)[ \n\t]+/g, '$1 ')
          .replace(/ (\.|,|\?|!)$/, '$1')
          .trim(' ');
        text += `"${getTimeStamp(startTime)}","${getTimeStamp(endTime)}","${
          speaker || ''
        }","${content}","${h}","${(tagsArr || []).join(', ')}" \n`;
      }
    }
    if (payload.length === 0)
      text += 'Please highlight content to populate this report';
    const b = new Blob([text], { type: 'text/csv' });
    saveAs(b, fileName, { autoBom: true });
    return Promise.resolve();
  };

  const downloadXlsAnnotations = async (fileName, payload, collectionId) => {
    await fetchDetails(collectionId);

    const worksheet = XLSX.utils.book_new();
    const rows = [];
    rows.push([]);
    rows.push(['Start', 'End', 'Speaker', 'Annotation', 'Highlighted', 'Tags']);

    for (let highlight of payload) {
      const {
        highlighted,
        content,
        startTime,
        endTime,
        speaker,
        tags = {}
      } = highlight;
      const tagsArr = Object.keys(tags);
      if (highlighted) {
        const h = highlighted
          .replace(/[ \n\t]+(!|\?|,|\.)[ \n\t]+/g, '$1 ')
          .replace(/ (\.|,|\?|!)$/, '$1')
          .trim(' ');
        rows.push([
          getTimeStamp(startTime),
          getTimeStamp(endTime),
          speaker || '',
          content,
          h,
          (tagsArr || []).join(', ')
        ]);
      }
    }

    if (payload.length === 0)
      rows.push(['Please highlight content to populate this report']);

    console.log({ rows });
    XLSX.utils.sheet_add_aoa(worksheet, rows);
    const wb = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(wb, fileName);

    return Promise.resolve();
  };

  const getAnnotations = async (isCsv, collectionId) => {
    await fetchDetails(collectionId);

    const state = store.getState();

    const annotations = selectGenerateAnnotations(state, collectionId);

    return isCsv
      ? downloadCsvAnnotations(
          getDownloadName('csv', collectionId),
          annotations,
          collectionId
        )
      : downloadXlsAnnotations(
          getDownloadName('xls', collectionId),
          annotations,
          collectionId
        );
  };

  const getBatchIds = (collectionIds, index, batchSize) => {
    const start = index * batchSize;
    const end = Math.min((index + 1) * batchSize, collectionIds.length);
    return collectionIds.slice(start, end);
  };

  // End of Internal Export Functions

  // Methods to be used by parent component

  const generateDocs = async (formatted, speakerHeader) => {
    await generateGeneric(generateDoc, [formatted, speakerHeader]);
  };

  const generateGeneric = async (
    callback,
    args,
    batchSize = BATCH_SIZE,
    batchDelay = BATCH_DELAY
  ) => {
    if (!collectionIds || collectionIds.length < 1) return;
    setCompletedCollections(new Map());
    setIsGeneratingDocs(true);

    try {
      const totalBatches = Math.ceil(collectionIds.length / batchSize);

      for (let i = 0; i < totalBatches; i++) {
        const batchIds = getBatchIds(collectionIds, i, batchSize);

        await Promise.all(
          batchIds.map(async (id) => {
            const collectionName = getName(id);
            try {
              await callback(...args, id);

              setCompletedCollections((prev) => {
                // This will ensure unique values
                return new Map([...prev, [id, collectionName]]);
              });
            } catch (err) {
              console.log(err);
            }
          })
        );

        // Delay between batches, except for the last one
        if (i < totalBatches - 1) {
          delay(batchDelay);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsGeneratingDocs(false);
    }
  };

  const generateCsv = async (
    batchSize = BATCH_SIZE,
    batchDelay = BATCH_SIZE
  ) => {
    if (!collectionIds || collectionIds.length < 1) return;
    setCompletedCollections(new Map());
    setIsGeneratingDocs(true);

    try {
      const totalBatches = Math.ceil(collectionIds.length / batchSize);

      for (let i = 0; i < totalBatches; i++) {
        const batchIds = getBatchIds(collectionIds, i, batchSize);

        await Promise.all(
          batchIds.map(async (id) => {
            const collectionName = getName(id);
            try {
              await downloadCsv(`${collectionName}.csv`, id);

              setCompletedCollections((prev) => {
                // This will ensure unique values
                return new Map([...prev, [id, collectionName]]);
              });
            } catch (err) {
              console.log(err);
            }
          })
        );

        // Delay between batches, except for the last one
        if (i < totalBatches - 1) {
          delay(batchDelay);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsGeneratingDocs(false);
    }
  };

  const generateText = async (
    speakers = true,
    filter,
    batchSize = BATCH_SIZE,
    batchDelay = BATCH_DELAY
  ) => {
    if (!collectionIds || collectionIds.length < 1) return;
    setCompletedCollections(new Map());
    setIsGeneratingDocs(true);

    try {
      const totalBatches = Math.ceil(collectionIds.length / batchSize);

      for (let i = 0; i < totalBatches; i++) {
        const batchIds = getBatchIds(collectionIds, i, batchSize);

        await Promise.all(
          batchIds.map(async (id) => {
            const collectionName = getName(id);
            try {
              const collectionText = await getText(speakers, filter, id);

              const b = new Blob([collectionText], {
                type: 'text/plain;charset=utf-8'
              });

              saveAs(b, getDownloadName('txt', id));

              setCompletedCollections((prev) => {
                // This will ensure unique values
                return new Map([...prev, [id, collectionName]]);
              });
            } catch (err) {
              console.log(err);
            }
          })
        );

        // Delay between batches, except for the last one
        if (i < totalBatches - 1) {
          delay(batchDelay);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsGeneratingDocs(false);
    }
  };

  const generateSrtSubtitles = async (size) => {
    await generateGeneric(generateSubtitles, ['srt', size]);
  };

  const generateVttSubtitles = async (size) => {
    await generateGeneric(generateSubtitles, ['vtt', size]);
  };

  const generateAnnotations = async (isCsv) => {
    await generateGeneric(getAnnotations, [isCsv]);
  };
  // End of Methods to be used by parent component

  return {
    generateDocs,
    generateCsv,
    generateText,
    generateSrtSubtitles,
    generateVttSubtitles,
    generateAnnotations,
    isGeneratingDocs,
    completedCollections
  };
};

export default useMultiExporter;
