import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDialog = (props) => {
  const {
    open,
    onCancel,
    onYes,
    onClose,
    title,
    content,
    yesText,
    noText,
    yesButtonColor = 'primary',
    yesButtonVariant = '',
    noButtonColor = 'primary',
    noButtonVariant = ''
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="draggable-dialog-title"
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          color={noButtonColor}
          variant={noButtonVariant}
        >
          {noText}
        </Button>
        <Button
          onClick={onYes}
          color={yesButtonColor}
          variant={yesButtonVariant}
        >
          {yesText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
